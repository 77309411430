import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanziEnvironmentModel, Environment } from '@kanzi-apes/kanzi-models';

@NgModule({
  imports: [CommonModule],
})
export class KanziUtilsModule {
  static forRoot(env: KanziEnvironmentModel): ModuleWithProviders<any> {
    return {
      ngModule: KanziUtilsModule,
      providers: [{ provide: Environment, useValue: env }],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule?: KanziUtilsModule) {
    if (parentModule) {
      throw new Error(
        `UtilModule is already loaded. Import it in the AppModule only`
      );
    }
  }
}

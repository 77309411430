import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private progress = new BehaviorSubject({
    percent: 0,
    packet: '1/1',
  });

  get progress$() {
    return this.progress.asObservable();
  }

  onProgress(percent: number, packet: string) {
    this.progress.next({
      percent,
      packet,
    });
  }

  onComplete(packet: string) {
    this.progress.next({
      percent:100,
      packet,
    });
  }
}

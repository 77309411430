<div
  style="width: 300px; text-align: center; margin: 20% auto"
  *ngIf="progressBar$ | async"
>
  <p-progressSpinner *ngIf="progressBar$ | async"></p-progressSpinner>
</div>
<div
  *ngIf="!(progressBar$ | async)"
  class="layout-wrapper"
  [ngClass]="containerClass"
  [class]="sidebarClass"
>
  <div class="layout-content-wrapper">
    <kanzi-kanzi-top-bar
      [environment]="envApp"
      [user]="user$ | async"
      [customers]="customers$ | async"
      [customerSelected]="customerSelected$ | async"
      [isParent]="isParent$ | async"
      [status]="monitoringStatusActual"
      [menuItems]="itemsMenu"
      (logout)="logout()"
      (changeCustomer)="selectCustomer($event)"
    ></kanzi-kanzi-top-bar>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
    <kanzi-footer [versionName]="appVersion"></kanzi-footer>
  </div>
  <app-alert-voice></app-alert-voice>
  <app-alert></app-alert>
  <kanzi-right-sidebar
    [modulesApp]="envApp.configModules"
    [actionSidebar]="(showRightMenu$ | async)!"
    [rolUserApp]="rolRightSide"
  >
  </kanzi-right-sidebar>
  <div class="layout-mask"></div>
</div>

export enum MESSAGES_ERROR {
  'The store order can not created' = 'No se puede crear el pedido de la tienda.',
  'There is not enough inventory at the location' = 'No hay suficiente inventario en la locación',
  'The reserve can not created' = 'La reserva no se puede crear',
  'There is not enough inventory' = 'No hay suficiente inventario',
}

export enum KEYS{
    'location'='Locación',
    'sku_id' = 'SKU ID',
    'external_id' = 'EXTERNAL ID',
    'sku_external_id' = 'EXTERNAL ID'
}
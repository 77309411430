/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { KanziControlErrorsService } from '../control-errors/kanzi-control-errors.service';
import { aditionalHeadersModel, FiltersModel } from '@kanzi-apes/kanzi-models';
import { KanziUtilService } from '../utils/kanzi-util.service';
import { ProgressBarService } from '@kanzi-apes/kanzi-ui';

export interface HttpDownloadEventModel {
  percent?: number | undefined;
  EventType: HttpEventType;
  response?: any;
}

@Injectable({
  providedIn: 'root',
})
export class KanziRestClientService {
  constructor(
    private http: HttpClient,
    private errorService: KanziControlErrorsService,
    private utilService: KanziUtilService,
    private progressBarService:ProgressBarService
  ) {}

  get<T>(
    url: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    return this.http
      .get<T>(url, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  getReports<T>(
    url: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    return this.http
      .get<T>(url, options)
      .pipe(catchError((_) => throwError(() => errorMsg)));
  }

  post<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    const body = JSON.stringify(data);
    return this.http
      .post<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  request<T>(
    method: string,
    url: string,
    data?: any,
    filters?: any,
    errorMsg?: string,
    responseType?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T | HttpEvent<T>> {
    let options: { headers?: any; body?: any; responseType?: any } = {
      headers: this.utilService.getHeaders(additionalHeaders),
    };
    let body = null;
    if (data) {
      let body = JSON.stringify(data);
      options = { ...options, body };
    }
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    if (responseType) {
      options = { ...options, responseType };
    }

    return this.http
      .request<T>(method, url, { ...options, observe: 'events' })
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  put<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    const body = JSON.stringify(data);
    return this.http
      .put<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  patch<T>(
    url: string,
    data: any,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    if (filters) {
      url = url + `?`;
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    const body = JSON.stringify(data);
    return this.http
      .patch<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  delete<T>(
    url: string,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    return this.http
      .delete<T>(url, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  deleteWithoutControl<T>(
    url: string,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    return this.http.delete<T>(url, options);
  }

  status(
    url: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<number> {
    return this.http
      .get(url, {
        headers: this.utilService.getHeaders(additionalHeaders),
        observe: 'response',
      })
      .pipe(
        map((response: any) => response.status),
        catchError((response) => of(response.status))
      );
  }

  postFormData<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeadersUploadFile(additionalHeaders),
    };
    return this.http
      .post<T>(url, data, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  patchFormData<T>(
    url: string,
    data: any,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeadersUploadFile(additionalHeaders),
    };
    if (filters) {
      url = url + `?`;
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    return this.http
      .patch<T>(url, data, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  downLoadData<T>(
    url: string,
    packet: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }

    const request = new HttpRequest('GET', url, null, {
      headers: this.utilService.getHeaders(additionalHeaders),
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request<T>(request).pipe(
      map((event) => this.getPercent(event)),
      tap((event) => this.progressBarControl(event,packet)),
      map((event) => this.eventResponse(event)),
      last(),
      catchError(this.errorService.handleError<T>(errorMsg))
    );
  }

  getPercent(event: HttpEvent<any>): HttpDownloadEventModel {
    switch (event.type) {
      case HttpEventType.DownloadProgress: {
        const percentDone = event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0;
        return {
          percent: percentDone,
          EventType: event.type,
        };
      }

      case HttpEventType.Response: {
        return { response: event.body, EventType: event.type };
      }

      default: {
        return { EventType: event.type };
      }
    }
  }

  progressBarControl(event: HttpDownloadEventModel, packet:string) {
    event.EventType === HttpEventType.DownloadProgress
      ? this.progressBarService.onProgress(event.percent??0,packet)
      : event.EventType === HttpEventType.Response
      ? this.progressBarService.onComplete(packet)
      : console.log('OTRO EVENTO',event);
  }

  eventResponse(event: HttpDownloadEventModel) {
    return event.EventType === HttpEventType.Response ? event.response : event;
  }
}

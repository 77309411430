<ul class="list-none p-0 m-0 border-top-1 border-300">
  <li
    *ngFor="let att of attributes"
    class="flex align-items-center py-3 px-2 flex-wrap surface-50"
  >
    <div class="text-500 w-full md:w-2 font-medium">
      {{ att.label | translate }}
    </div>
    <div class="text-900 w-full md:w-10">
      <div
        *ngIf="att.subfield; then nested_object_content; else normal_content"
      ></div>
      <ng-template #nested_object_content>
        <div
          *ngIf="
            att.childfield;
            then child_nested_object_content;
            else child_normal_content
          "
        ></div>
        <ng-template #child_nested_object_content>
          <div *ngIf="data && data[att.field]">
            <label>{{ data[att.field][att.subfield][att.childfield] }}</label>
          </div>
        </ng-template>
        <ng-template #child_normal_content>
          <div *ngIf="data && data[att.field]">
            <label>{{ data[att.field][att.subfield] }}</label>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #normal_content>
        <div *ngIf="data && data[att.field]">
          <label>{{ data[att.field] }}</label>
        </div>
      </ng-template>
    </div>
  </li>
</ul>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KanziEnvironmentModel, CUSTOMERTYPES } from '@kanzi-apes/kanzi-models';
export const environment: KanziEnvironmentModel ={
  production: false,
  kongApi: 'https://api-fulfillment-zenderbox-staging.technoapes.io/',
  kongTrackingFile:
    'https://api-fulfillment-zenderbox-staging.technoapes.io/logistics/tracking-files/',
  formats: {
    date: 'MM/DD/YYYY',
  },
  apiModules: {
    auth: 'auth/',
    config: 'core_config/',
    inventory: 'inventory/',
    operations: 'operations/',
    rfid: 'rfid/',
    audits: 'inventory/audits/',
    logistics: 'logistics/',
    customers: 'customers/',
    integrations: 'integrations/',
    maintenance: '',
    stock: '',
  },
  configUI: { mainMenuStyle: 'horizontal', scale: 12 },
  assets: {
    logo: 'zenderbox-logo.png',
    background: 'rsz_bg-1.jpg',
    root: 'assets/',
    rootClient: 'assets-clients/zenderbox/',
    paths: {
      css: 'css/',
      logos: 'images/logos/',
      backgrounds: 'images/backgrounds/',
      js: 'js/',
    },
  },
  modules: {
    customerType: CUSTOMERTYPES.FULFILLMENT,
    analyticsReports: {
      parent: false,
      sales: false,
      inventory: false,
    },
    dashboards: true,
    contacts: true,
    products: {
      parent: true,
      products: true,
      assets: false,
      maintenanceTrace: false,
    },
    inventories: {
      parent: true,
      products: true,
      assets: false,
      booking: true,
      bookingCreate: true,
      bookingList: true,
    },
    moves: {
      parent: true,
      dashboards: false,
      moves: true,
    },
    production: {
      parent: true,
      productionOrder: true,
      productionLocationType: 'WAREHOUSE',
      purchaseOrder: true,
      purchaseLocationType: 'WAREHOUSE',
      printOrder: true,
      filterOrder: true,
      external_id: true,
      hideTagTemplate:true
    },
    operations: {
      parent: false,
      storeOrder: false,
      distributionOrder: false,
      workOrder: false,
      packings: false,
      dispatchPacks: true,
    },
    audits: {
      parent: true,
      products: true,
      assets: false,
      calendar: false,
    },
    logistics: {
      parent: true,
      shippingOrders: true,
      trackOrderCreate: false,
      trackings: true,
      printers: true,
      pickingFile: true,
    },
    logisticPacking: {
      parent: true,
      list: true,
      listStates: true,
      create: false,
      filterUser: false,
    },
    maintenance: {
      parent: false,
      scheduler: false,
      orders: false,
    },
    alerts: false,
  },
  configModules: {
    locations: true,
    logistics: true,
    customers: true,
    users: true,
  },
  viewRigthMenu: true,
};


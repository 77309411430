import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ConfigTableModel,
  DataListModel,
  FilterTableModel,
  FilterTreeTableModel,
} from '@kanzi-apes/kanzi-models';
import { TreeNode } from 'primeng/api';
import * as moment from 'moment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'kanzi-dynamic-tree-table',
  templateUrl: './kanzi-dynamic-tree-table.component.html',
  styleUrls: ['./kanzi-dynamic-tree-table.component.scss'],
})
export class KanziDynamicTreeTableComponent implements OnInit, OnChanges {
  @Input() pending: boolean = false;
  @Input() cols: any[] = [];
  @Input() configs: ConfigTableModel | null = null;
  @Input() lazyMode: boolean = false;
  @Input() totalRecords: number = 0;
  @Input() filters: any | null = null;
  @Input() dataList: { data: any; leaf: boolean }[] = [];
  @Input() childrenDataList: {
    parent_id: any;
    dataList: DataListModel;
  } | null = null;
  @Input()
  set nodeActual(newNode: TreeNode | null) {
    if (newNode) {
      this.nodeSelected = newNode;
      this.dataList = [...this.dataList];
      this.exportDataList = this.exportDataList.concat(
        newNode.children ? newNode.children.map((node) => node.data) : []
      );
    }
  }
  @Output() selectionEvent = new EventEmitter<any>();
  @Output() paginationEvent = new EventEmitter<FilterTableModel>();
  @Output() expandEvent = new EventEmitter<{
    nodeActual: TreeNode;
    filter: FilterTreeTableModel;
  }>();
  @Output() EditRow = new EventEmitter<any>();

  list: any[] = [];
  fromChildren = false;
  nodeSelected: TreeNode | null = null;
  exportExcelColumns: string[] = [];
  exportDataList: TreeNode[] = [];
  rangeDates: Date[] = [];
  formComents: UntypedFormGroup = this.fb.group({
    comments: [''],
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnChanges() {
    this.exportExcelColumns = [];
    this.exportExcelColumns = this.cols.map((col) => col.field);
    if (this.dataList && this.exportDataList.length === 0) {
      this.exportDataList = this.exportDataList.concat(
        this.dataList.map((node) => node.data)
      );
    }
  }
  ngOnInit() {}

  loadNodes(event: any) {
    if (this.filters) {
      const pag = event.first / event.rows + 1;
      this.filters = {
        ...this.filters,
        page: pag,
        page_size: 10,
      };
      this.paginationEvent.emit(this.filters);
    }
  }

  nodeExpand(event: any) {
    this.nodeSelected = event.node;
    if (
      this.nodeSelected &&
      !(this.nodeSelected?.children && this.nodeSelected.children.length > 0)
    ) {
      this.expandEvent.emit({
        nodeActual: this.nodeSelected,
        filter: {
          page: 1,
          page_size: 1000,
          customer_id: this.filters ? this.filters.customer_id : 0,
          parent_id: this.nodeSelected.data.node_id,
        },
      });
    }
  }

  onRowSelect(node:any) {
    this.selectionEvent.emit(node);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.exportDataList, {
        cellDates: true,
        header: this.exportExcelColumns,
      });
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['data'],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        this.configs
          ? this.configs.fileName
            ? this.configs.fileName
            : 'kanzi-report'
          : 'kanzi-report'
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  selectDateRange(range: Date[]) {
    if (range[1]) {
      const initDate = moment(range[0]).format('MM/DD/YYYY');
      const endDate = moment(range[1]).format('MM/DD/YYYY');
      const filter: FilterTableModel = {
        ...this.filters,
        created_min: initDate,
        created_max: endDate,
      };
    }
  }

  onComents(rowData: any) {
    if (this.formComents.valid) {
      this.EditRow.emit({
        id: rowData.id,
        comments: this.formComents.get('comments')?.value,
      });
    }
  }

  clearFilters() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import {
  TRANSFER_STATUS,
  PRINT_ORDER_STATES,
  REPORTSOURCES,
} from '@kanzi-apes/kanzi-models';
import { words } from '@kanzi-apes/kanzi-utils';

@Pipe({
  name: 'kanziAppPipes',
})
export class KanziAppPipes implements PipeTransform {
  transform(value: any, pipeName?: string): string {
    return  typeof value=="string"? (words[value] || value):value;
  }
}

@Pipe({
  name: 'booleans',
})
export class booleansPipes implements PipeTransform {
  transform(value: any, pipeName?: string): string {
    return typeof value=="boolean" ? '' :value;
  }
}

@Pipe({
  name: 'kanziNameAbrv',
})
export class KanziNameAbrv implements PipeTransform {
  transform(value: string, args?: any): string {
    if (value) {
      let words: string[] = value.split(' ');
      words = words.map((word) => {
        return word.charAt(0);
      });
      return words.join('').toUpperCase();
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    if (value === 0) {
      return 'Masculino';
    } else if (value === 1) {
      return 'Femenino';
    } else {
      return null;
    }
  }
}

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const n = parseInt(value);
    return n.toLocaleString();
  }
}

@Pipe({
  name: 'decimalFormat',
})
export class DecimalFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    console.log("🚀 ~ DecimalFormatPipe ~ value:", value)
    const formatNumber = new Intl.NumberFormat('es-CO',{
      style:'decimal',
      minimumFractionDigits:0
    })

    return formatNumber.format(value);
  }
}

@Pipe({
  name: 'stateWorkOrder',
})
export class StateWorkOrderPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return 'Terminada';
    } else {
      return 'Sin Terminar';
    }
  }
}

@Pipe({
  name: 'gtinStructure',
})
export class GTINStructurePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'GTIN 8';
      case 1:
        return 'GTIN 12';
      case 2:
        return 'GTIN 13';
      case 3:
        return 'GTIN 14';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'sgtinFilter',
})
export class SGTINFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 1:
        return 'Producto';
      case 4:
        return 'Empaque';
      case 2:
        return 'Caja';
      case 6:
        return 'Estiba';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'statusReportAudits',
})
export class StatusReportAuditsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'Procesando';
      case 1:
        return 'Completado';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'statusReportLinesAudits',
})
export class StatusReportLinesAuditsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'Extra';
      case 1:
        return 'Contado';
      case 2:
        return 'Encontrado';
      case 3:
        return 'No Encontrado';
      case 4:
        return 'Esperado';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'transferStatus',
})
export class TransferStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case TRANSFER_STATUS.COMPLETED:
        return 'Completado';
      case TRANSFER_STATUS.INCOMPLETE:
        return 'Incompleto';
      case TRANSFER_STATUS.PROCESSING:
        return 'Procesando';
      case TRANSFER_STATUS.DRAFT:
        return 'Borrador';
      case TRANSFER_STATUS.INVALID:
        return 'Inválido';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'printOrderStatus',
})
export class PrintOrderStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case PRINT_ORDER_STATES.ISSUED:
        return 'Sin Imprimir';
      case PRINT_ORDER_STATES.IN_PROCESS:
        return 'En Proceso';
      case PRINT_ORDER_STATES.PRINTED:
        return 'Impresa';
      case PRINT_ORDER_STATES.ABORTED:
        return 'Abortada';
      default:
        return null;
    }
  }
}

@Pipe({
  name: 'reporterSource',
})
export class ReporterSourcePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    switch (value) {
      case REPORTSOURCES.DESKTOP_APP:
        return 'Aplicación de escritorio';
      case REPORTSOURCES.FIXED_PORTAL:
        return 'Portal';
      case REPORTSOURCES.HANDHELD:
        return 'Handheld';
      case REPORTSOURCES.POS_DEVICE:
        return 'Dispositivo POS';
      case REPORTSOURCES.PRINTER:
        return 'Impresora';
      case REPORTSOURCES.SYSTEM:
        return 'Sistema';
      case REPORTSOURCES.WEB_APP:
        return 'Aplicación Web';
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'kanziNameOrder',
})
export class kanziNameOrderPipe implements PipeTransform {
  transform(value: any[], args?: any): any[] {
    if (value) {
      return value.sort((a, b) => (a.value > b.value ? 1 : -1));
    } else {
      return [];
    }
  }
}

@Pipe({
  name: 'kanziDisplayNameOrder',
})
export class kanziDisplayNameOrder implements PipeTransform {
  transform(value: any[], args?: any): any[] {
    if (value) {
      const newValue = [...value];
      return newValue.sort((a, b) =>
        a.display_name > b.display_name ? 1 : -1
      );
    } else {
      return [];
    }
  }
}

@Pipe({
  name: 'kanziLocationsNameOrder',
})
export class kanziLocationsNameOrder implements PipeTransform {
  transform(value: any[], args?: any): any[] {
    if (value) {
      const newValue = [...value];
      return newValue.sort((a, b) => (a.city_name > b.city_name ? 1 : -1));
    } else {
      return [];
    }
  }
}

<p-table [value]="services" dataKey="id" editMode="row">
  <ng-template pTemplate="header">
    <tr>
      <th>Descripción</th>
      <th>Precio Base</th>
      <th>Cantidad</th>
      <th>Precio Total</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="row">
      <td>
        {{ row.description }}
      </td>
      <td>
        {{ row.price | currency: 'USD' }}
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-inputNumber
            [showButtons]="true"
            [style]="{ width: '100%' }"
            [(ngModel)]="row.amount"
            [minFractionDigits]="envService.environment?.isQuantity ? 1 : 0"
          ></p-inputNumber>
          </ng-template>
          <ng-template pTemplate="output">
            {{ row.amount }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        {{ row.total_price | currency: 'USD' }}
      </td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(row)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-trash"
            (click)="onRowDelete(row)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(row, ri)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(row, ri)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div
      class="grid justify-content-start align-items-center pt-2 pl-2"
      style="width: 100%"
    >
      <button
        type="button"
        icon="fa-solid fa-plus"
        pButton
        class="p-button-help mr-3"
        (click)="viewAddServices = true"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="5">
        {{ 'Registros no encontrados.' | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>

<form [formGroup]="formService">
  <p-dialog
    [(visible)]="viewAddServices"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50%', 'min-height': '400px' }"
    [draggable]="false"
    [modal]="true"
    [autoZIndex]="true"
    header="Agregar servicios"
    [maximizable]="false"
    class="p-fluid"
  >
    <div class="field">
      <label>Servicio</label>
      <p-dropdown
        [options]="servicesListOptions"
        [filter]="true"
        filterBy="name"
        formControlName="service"
        placeholder="Seleccionar"
        appendTo="body"
        optionLabel="name"
      ></p-dropdown>
    </div>
    <div class="field">
      <label>Cantidad</label>
      <p-inputNumber
        [showButtons]="true"
        [style]="{ width: '100%' }"
        formControlName="amount"
        [minFractionDigits]="envService.environment?.isQuantity ? 1 : 0"
      ></p-inputNumber>
    </div>
    <div class="field">
      <label>Precio Base</label>
      <p-inputNumber
        formControlName="basePrice"
        [style]="{ width: '100%' }"
        [maxFractionDigits]="0"
        inputId="currency-us"
        mode="currency"
        currency="USD"
        locale="en-US"
      >
      </p-inputNumber>
    </div>
    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        type="button"
        label="Cerrar"
        class="p-button-text p-button-secondary"
        (click)="viewAddServices = false"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Agregar"
        class="p-button-text"
        (click)="addServices()"
      ></button>
    </ng-template>
  </p-dialog>
</form>

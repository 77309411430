import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import * as fromAuth from '../reducers';
import { Store, select } from '@ngrx/store';
import {
  UserModel,
  Token,
  Authenticate,
  ModuleAction,
  UserRole,
} from '@kanzi-apes/kanzi-models';
import { tap, catchError } from 'rxjs/operators';
import { KanziEnvironmentModel } from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.1.0
 *
 * Service with the login and authentication functions.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loggedIn = false;
  private role: UserRole | null = null;
  private user: UserModel | null = null;
  user$ = this.store.pipe(select(fromAuth.getUser));
  modulesUser: ModuleAction[] = [];
  private environment: KanziEnvironmentModel | null = null;
  private token: Token | null;
  private tokenUrl: string = '';
  private profileUserUrl: string = '';
  private userUrl: string = '';
  private authUrl: string = '';

  /**
   *
   * @param http {HttpClient}
   * @param store {Store}
   * @param alertService {AlertService} Service to show alert messages.
   */
  constructor(
    private http: HttpClient,
    private store: Store<fromAuth.State>,
    private envService: EnvironmentService
  ) {
    this.loggedIn = !!localStorage.getItem('kanziUserToken');
    if (this.envService.environment) {
      this.environment = this.envService.environment;
      this.tokenUrl =
        this.environment.kongApi +
        this.environment.apiModules.auth +
        'token/create/'; // URL to web ap
      this.profileUserUrl =
        this.environment.kongApi +
        this.environment.apiModules.config +
        'users/current_user/'; // URL to web api
      this.userUrl =
        this.environment.kongApi + this.environment.apiModules.config + 'users'; // URL to web api
      this.authUrl =
        this.environment.kongApi + this.environment.apiModules.auth + 'token/';
    }
    if (this.isLogedIn()) {
      this.userProfile().subscribe((user) => {
        if (user) {
          if (user.configs) {
            this.modulesUser = user.configs.active_web_modules;
            this.role = user.configs.role;
          }
        }
      });
    }
    this.token = null;
  }

  /**
   *
   * @param auth {Authenticate} Auth model data to send to the server.
   * @returns Observable {Token} Return the token data.
   *
   * Function to send throw POST the login data.
   */
  login(auth: Authenticate): Observable<Token | null> {
    if (auth) {
      const body = JSON.stringify(auth);
      const options = { headers: this.getHeaders() };
      return this.http.post<Token>(this.tokenUrl, body, options).pipe(
        tap((token: Token) => {
          this.token = token;
          if (this.token && this.token.auth_token) {
            localStorage.setItem('kanziUserToken', JSON.stringify(this.token));
            if (this.isLogedIn()) {
              this.userProfile().subscribe((user:UserModel) => {
                if (user) {
                  if (user.configs) {
                    this.modulesUser = user.configs.active_web_modules;
                    this.role = user.configs.role;
                  }
                }
              });
            }
          }
        }),
        catchError(this.handleError<Token>('login'))
      );
    } else {
      return of(null);
    }
  }

  /**
   * Function to logout and remove the token.
   */
  logout() {
    // remove user from local storage to log user out
    const url = `${this.authUrl}destroy/`;
    const options = { headers: this.getHeaders() };
    localStorage.removeItem('kanziUserToken');
    this.http.post<any>(url, options);
  }

  /**
   * @returns Observable {User} The user data from the server.
   *
   *
   * Function to get the user profile information.
   */
  userProfile(): Observable<UserModel> {
    const url = `${this.profileUserUrl}`;
    return this.http
      .get<UserModel>(url, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError<UserModel>('currentUser')));
  }

  /**
   *
   * @param id {number} User ID.
   * @returns Observable {User} User data from the server.
   *
   * Function to get the User data by ID from the server.
   */
  getUserById(id: number): Observable<UserModel> {
    const url = `${this.userUrl}/${id}`;
    const options = { headers: this.getHeaders() };
    return this.http
      .get<UserModel>(url, options)
      .pipe(catchError(this.handleError<UserModel>('getUserById')));
  }

  /**
   * @returns {boolean} True if the user is logged.
   *
   * Function to check if the user is logged. Check the token in the local storage.
   */
  isLogedIn(): boolean {
    return !!localStorage.getItem('kanziUserToken');
  }

  /**
   * @returns Headers {HttpHeaders} Headers to be used in the http requests.
   *
   * Function to create the Http Headers.
   */
  getHeaders(): HttpHeaders {
    const localItem = localStorage.getItem('kanziUserToken');
    if (localItem) {
      const token = JSON.parse(localItem);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token.auth_token,
      });
      return headers;
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return headers;
    }
  }

  /**
   *
   * @param code {string} Permission user code.
   * @returns {boolean} Return true if the user have permission.
   *
   * Function to check the user permissions.
   */
  hasPermission(code: string): boolean {
    if (
      this.modulesUser &&
      this.modulesUser.find((module) => {
        return module.code === code;
      })
    ) {
      return true;
    } else {
      return false;
    }
  }

  viewConfigMenu(): boolean {
    if (this.role) {
      switch (this.role.name) {
        case 'ADMIN':
          return true;
        case 'SUPER_ADMIN':
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  viewConfigOption(role: string): boolean {
    if (this.role?.name === 'SUPER_ADMIN') {
      return true;
    } else {
      return this.role?.name === role;
    }
  }

  isParent(user: UserModel): boolean {
    if (
      !(user.configs && user.configs.customer && user.configs.customer.parent)
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @param operation
   * @param result
   *
   * Function to control the errors.
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return throwError(error);
    };
  }
}

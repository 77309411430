import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ConfigTableModel,
  DataListModel,
  RevisionLinePartModel,
} from '@kanzi-apes/kanzi-models';
import { MessageService } from '@kanzi-apes/kanzi-prime-ui';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import autoTable from 'jspdf-autotable';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'kanzi-kanzi-refill-edit-table',
  templateUrl: './kanzi-refill-edit-table.component.html',
  styleUrls: ['./kanzi-refill-edit-table.component.scss'],
})
export class KanziRefillEditTableComponent implements OnInit, OnChanges {
  @Input() dataSource: DataListModel | null;
  @Input() configs: ConfigTableModel | null;
  @Input() colSource: any[];
  @Input() pending: boolean;  
  @Output() onDisplay = new EventEmitter<boolean>();
  @Output() RefillSelected = new EventEmitter<{
    index: number;
    item: RevisionLinePartModel;
  }>();
  @Output() deleteRow = new EventEmitter<{ index: number; item: any }>();
  @ViewChild('dt') dt!: any;

  cloneLines: { [id: number]: any } = {};
  RefillList: any[] = [];
  exportColumns: any[] = [];
  newDataAmount: number = 0;
  showFilter = false;
  exportExcelColumns: string[] = [];
  columSelect: any;
  objAux: {[name: string]: any} = {};
  formFilters: UntypedFormGroup | null = null;

  constructor(private messageService: MessageService, private fb: UntypedFormBuilder, public envService:EnvironmentService) {
    this.dataSource = null;
    this.configs = null;
    this.colSource = [];
    this.pending = false;
  }

  ngOnInit(): void {
    const fields = this.colSource.map((item) => item.field);
    fields.forEach((columns) => {
      this.objAux[columns] = ['', [Validators.required]];
    });
    this.formFilters = this.fb.group(this.objAux);

    this.formFilters.valueChanges.subscribe((value) => {
      this.dt.filter(
        value[this.columSelect.field],
        this.columSelect.field,
        'in'
      );
    });
  }

  ngOnChanges() {
    if (this.dataSource) {
      this.RefillList = [];
      this.RefillList = this.RefillList.concat(this.dataSource.results);
    } else {
      this.RefillList = [];
    }
    this.exportColumns = this.colSource.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportExcelColumns = [];
    this.exportExcelColumns = this.colSource.map((col) => col.field);
  }

  onRowEditInit(rowData: any) {
    this.newDataAmount = rowData.amount;
    this.cloneLines[rowData.id] = { ...rowData };
  }

  onRowDelete(index: number, rowData: any) {
    this.deleteRow.emit({ index: index, item: rowData });
  }

  onRowEditSave(index: number, rowData: any) {
    if (this.newDataAmount > 0) {
      delete this.cloneLines[rowData.id];
      this.RefillSelected.emit({
        index: index,
        item: { ...rowData, amount: this.newDataAmount },
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'La cantidad debe ser mayor a 0.',
      });
    }
  }

  onRowEditCancel(rowData: any, index: number) {
    this.RefillList[index] = this.cloneLines[rowData.id];
    delete this.cloneLines[rowData.id];
  }

  showDialogToAdd() {
    this.onDisplay.emit(true);
  }

  showFilters() {
    this.showFilter = !this.showFilter;
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const rows = this.dataSource?.results.map((item) => {
        let objAux = {};
        this.exportExcelColumns.forEach((column, i) => {
          if (item[column]) {
            Object.assign(objAux, { [column]: item[column] });
          }
        });
        return objAux;
      });
      if (rows) {
        const worksheet = xlsx.utils.json_to_sheet(rows, {
          cellDates: true,
          header: this.exportExcelColumns,
        });
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(
          excelBuffer,
          this.configs
            ? this.configs.fileName
              ? this.configs.fileName
              : 'kanzi-report'
            : 'kanzi-report'
        );
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      const doc = new jsPDF.default('landscape');
      autoTable(doc, {
        columns: this.exportColumns,
        body: this.dataSource?.results,
        tableWidth: 'auto',
        pageBreak: 'auto',
        margin: 10,
      });
      doc.save(this.configs ? this.configs.fileName : 'kanzi-report');
    });
  }

  selectFilter($event: any, col: any, overlaypanel: OverlayPanel) {
    this.columSelect = col;
    overlaypanel.toggle($event);
  }

  clearFilterColumn(field: string, overlaypanel: OverlayPanel) {
    this.formFilters?.get(field)?.reset();
    if (this.formFilters?.pristine) {
      this.formFilters.reset();
      this.dt.reset();
    }
    overlaypanel.hide();
  }
}

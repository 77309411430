import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, timer, Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as fromAuth from '@kanzi-apes/kanzi-auth';
import * as fromCustomer from '@kanzi-apes/kanzi-customer';
import * as Auth from '@kanzi-apes/kanzi-auth';
import * as layout from '../../actions/layout';
import * as Customer from '@kanzi-apes/kanzi-customer';
import * as Monitoring from '../../actions/monitoring';
import * as lcStorage from '@kanzi-apes/kanzi-utils';
import { ViewFunctions } from '@kanzi-apes/kanzi-utils';
import { LoginService } from '@kanzi-apes/kanzi-auth';
import { AlertVoiceService } from '@kanzi-apes/kanzi-alerts';
import { KanziMonitoringHandlerService } from '@kanzi-apes/kanzi-rest-api';
import {
  KanziStatus,
  CustomerModel,
  StatusApiMsgModel,
  CustomerListModel,
  StateConnectionModel,
  LayoutModel,
  UserModel,
} from '@kanzi-apes/kanzi-models';
import { takeUntil, take, tap, filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ItemsMenu } from '../../../configs/main-menu';
import { PrimeNGConfig } from 'primeng/api';
import { menuValidator } from '@kanzi-apes/kanzi-ui-configs';
import { MenuItem } from 'primeng/api';
import kanziVersion from 'kanziVersion.json';
import { DiamondLayoutService } from '@kanzi-apes/kanzi-diamond-ui';
import { KanziTopBarComponent, MainMenuService } from '@kanzi-apes/kanzi-ui';
import { NavigationEnd, Router } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
/**
 *
 * Component to manage the main layout page for Zenderbox
 */
@Component({
  selector: 'kanzi-apes-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutZenderboxComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  itemsMenu: MenuItem[] = [];
  isLogged$ = this.storeAuth.select(fromAuth.getLoggedIn);
  user$ = this.storeAuth.select(fromAuth.getUser);
  progressBar$: Observable<boolean> = this.store.select(
    fromRoot.getShowProgressBar
  );
  isParent$: Observable<boolean> = this.storeAuth.select(
    fromRoot.getCustomerParent
  );
  customers$: Observable<CustomerListModel | null> = this.store.select(
    fromCustomer.getCustomersList
  );
  customerSelected$: Observable<CustomerModel | null> = this.store.select(
    fromCustomer.getCustomerSelected
  );
  monitoringStatus$: Observable<StateConnectionModel | null> = this.store
    .select(fromRoot.getMonitoringStatus)
    .pipe(
      tap(
        (response: any) =>
          (this.monitoringStatusActual = response?.actual
            ? response?.actual
            : '')
      )
    );
  monitoringStatusMsg$: Observable<StatusApiMsgModel | null> =
    this.store.select(fromRoot.getMonitoringStatusMsg);

  showMenuMain$: Observable<string> = this.store
    .select(fromRoot.getShowMenuMain)
    .pipe(tap((response: string) => (this.menuStatus = response)));
  showRightMenu$: Observable<string> = this.store.select(
    fromRoot.getShowRightMenu
  );

  heightDiv: string = '';
  value = 0;
  functions: ViewFunctions | null = null;
  apiUrl = `${environment.kongApi}auth/me`;
  envApp = environment;
  menuStatus: string = '';
  logoPath: string =
    environment.assets.rootClient +
    environment.assets.paths.logos +
    environment.assets.logo;
  monitoringStatusActual: string = '';
  ripple: boolean = false;
  appVersion: string = '';
  rolRightSide: any;
  overlayMenuOpenSubscription: Subscription;
  menuOutsideClickListener: any;
  menuScrollListener: any;

  get containerClass() {
    return {
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-compact': this.layoutService.config.menuMode === 'compact',
      'layout-reveal': this.layoutService.config.menuMode === 'reveal',
      'layout-drawer': this.layoutService.config.menuMode === 'drawer',
      'layout-sidebar-dim': this.layoutService.config.colorScheme === 'dim',
      'layout-sidebar-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-overlay-active':
        this.layoutService.state.overlayMenuActive ||
        this.layoutService.state.staticMenuMobileActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config.menuMode === 'static',
      'p-ripple-disabled': !this.layoutService.config.ripple,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored,
    };
  }

  get sidebarClass() {
    return this.layoutService.config.colorScheme === 'light'
      ? `layout-sidebar-${this.layoutService.config.menuTheme}`
      : '';
  }

  @ViewChild(KanziTopBarComponent) appTopbar!: KanziTopBarComponent;

  /**
   *
   * @param store {Store} Root State
   * @param storeAuth {Store} Auth State
   * @param loginService {LoginService} Service to get login functions.
   * @param alertVoiceService {AlertVoiceService} Service to send warning message.
   */
  constructor(
    private store: Store<fromRoot.State>,
    private storeAuth: Store<fromAuth.State>,
    private storeCustomer: Store<fromCustomer.State>,
    private loginService: LoginService,
    private alertVoiceService: AlertVoiceService,
    private kanziMonitoring: KanziMonitoringHandlerService,
    private primengConfig: PrimeNGConfig,
    private layoutService: DiamondLayoutService,
    private router: Router,
    private renderer: Renderer2,
    private mainMenuService: MainMenuService,
    private readonly joyrideService: JoyrideService
  ) {
    this.primengConfig.ripple = true;
    this.appVersion = kanziVersion.version;
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appTopbar.appSidebar.el.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.appSidebar.el.nativeElement.contains(
                  event.target
                ) ||
                this.appTopbar.menuButton.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.menuButton.nativeElement.contains(event.target)
              );
              if (isOutsideClicked) {
                this.hideMenu();
              }
            }
          );
        }

        if (
          (this.layoutService.isHorizontal() ||
            this.layoutService.isSlim() ||
            this.layoutService.isCompact()) &&
          !this.menuScrollListener
        ) {
          this.menuScrollListener = this.renderer.listen(
            this.appTopbar.appSidebar.menuContainer.nativeElement,
            'scroll',
            (event) => {
              if (this.layoutService.isDesktop()) {
                this.hideMenu();
              }
            }
          );
        }

        if (this.layoutService.state.staticMenuMobileActive) {
          this.blockBodyScroll();
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
        this.unblockBodyScroll();
      });
  }

  ngOnInit(): void {
    this.store.dispatch(new layout.ShowProgressBar());
    this.user$.subscribe((user: UserModel | null) => {
      if (user && user.configs) {
        this.itemsMenu = menuValidator(
          ItemsMenu,
          user.configs.active_web_modules
        );
        this.rolRightSide = user.configs.role.name;
        this.store.dispatch(new layout.HideProgressBar());
        if (this.loginService.isParent(user)) {
          this.store.dispatch(new layout.ActivateCustomerParent());
          this.storeCustomer.dispatch(
            new Customer.LoadList({
              page: 1,
              page_size: 10,
              parent_id: user.configs.customer.id,
            })
          );
        } else {
          this.storeCustomer.dispatch(
            new Customer.Selected(user.configs.customer)
          );
        }

        this.customerSelected$.subscribe((customer: CustomerModel | null) => {
          if (!customer) {
            let localState = lcStorage.loadState();
            if (!(localState && localState.customer)) {
              this.storeCustomer
                .select(fromCustomer.getCustomersList)
                .subscribe((list: CustomerListModel | null) => {
                  if (list) {
                    localState = {
                      ...localState,
                      customer: list.results[0],
                    };
                    lcStorage.saveState(localState);
                    this.storeCustomer.dispatch(
                      new Customer.Selected(list.results[0])
                    );
                  }
                });
            } else {
              this.storeCustomer.dispatch(
                new Customer.Selected(localState.customer)
              );
            }

            const kanziTourData = lcStorage.loadKanziTour();

            if (
              kanziTourData === undefined ||
              !kanziTourData.tourCompleted ||
              this.appVersion !== kanziTourData.kanziVersion
            ) {
              this.joyrideService.startTour({
                steps: [
                  'logoStep',
                  'mainMenuStep',
                  'profileStep',
                  'langStep',
                  'configStep',
                  'versionStep',
                ],
                customTexts: {
                  prev: 'Prev',
                  next: 'Siguiente',
                  close: 'Cerrar',
                  done: 'Hecho',
                },
              });
              lcStorage.saveKanziTour({
                tourCompleted: true,
                kanziVersion: this.appVersion,
              });
            }


          }
        });
      }
    });
    this.functions = new ViewFunctions();
    this.progressBar$ = this.store.select(fromRoot.getShowProgressBar);

    this.storeAuth
      .select(fromAuth.selectAuthStatusState)
      .subscribe((status: any) => {
        let localState = lcStorage.loadState();
        localState = {
          ...localState,
          auth: status,
        };
        lcStorage.saveState(localState);
      });

    window.addEventListener('resize', this.sizeWindow);
    window.addEventListener('online', this.updateIndicator.bind(this));
    window.addEventListener('offline', this.updateIndicator.bind(this));

    this.isLogged$.subscribe((authed: boolean) => {
      if (authed) {
        this.storeAuth.dispatch(new Auth.UserProfileLoad());
      }
    });

    this.monitoringStatus$.subscribe((status: any) => {
      if (status && status.actual !== status.previous) {
        this.monitoringStatusMsg$.pipe(take(1)).subscribe((msg: any) => {
          if (msg) {
            this.alertVoiceService.message(msg);
          }
        });
      }
    });

    timer(10000, 120000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sec: any) => {
        this.kanziMonitoring
          .getApiStatus(this.apiUrl)
          .subscribe((response: any) => {
            if (
              response &&
              (response.type === KanziStatus.ERROR ||
                response.type === KanziStatus.WARN)
            ) {
              this.store.dispatch(new Monitoring.StatusOffline(response));
            } else if (response && response.type === KanziStatus.SUCCESS) {
              this.store.dispatch(new Monitoring.StatusOnline(response));
            }
          });
      });
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.mainMenuService.reset();
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }
    this.unblockBodyScroll();
  }

  /**
   * Function to dispatch the logout option.
   */
  logout() {
    lcStorage.saveState({
      auth: {
        loggedIn: false,
        user: null,
        token: null,
      },
    });

    this.storeAuth.dispatch(new Auth.Logout());
    this.store.dispatch(new layout.DeactivateCustomerParent());
    this.loginService.logout();
  }

  /**
   * Function to adapt the window height by default.
   */
  sizeWindow() {
    this.heightDiv = window.innerHeight - 50 + 'px';
  }

  /**
   *
   * @param event {any}
   *
   * Function to know if the browser is online.
   */
  updateIndicator(event: any) {
    if (event.type === 'online') {
      const msg = 'Se ha establecido conexión de red.';
      this.functions?.changeFavicon('active');
      this.store.dispatch(
        new Monitoring.StatusOnline({
          type: KanziStatus.SUCCESS,
          title: 'En Linea',
          msg: msg,
        })
      );
    } else if (event.type === 'offline') {
      const msg = 'Se ha perdido la conexión de red.';
      this.functions?.changeFavicon('error');
      this.store.dispatch(
        new Monitoring.StatusOffline({
          type: KanziStatus.ERROR,
          title: 'Fuera de Linea',
          msg: msg,
        })
      );
    }
  }

  selectCustomer(event: CustomerModel) {
    let localState = lcStorage.loadState();
    localState = {
      ...localState,
      customer: event,
    };
    lcStorage.saveState(localState);
    this.store.dispatch(new Customer.Selected(event));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserModel } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'kanzi-kanzi-user-top-menu',
  templateUrl: './kanzi-user-top-menu.component.html',
  styleUrls: ['./kanzi-user-top-menu.component.scss'],
})
export class KanziUserTopMenuComponent implements OnInit {
  @Input() user: UserModel | null;
  @Input() logoPath: string;
  @Input() imageUserPath: string;
  @Input() topbarUserMenuActive: boolean;
  @Output() logoutEvent = new EventEmitter();
  @Output() topbarUserMenuButtonClick = new EventEmitter();

  constructor() {
    this.user = null;
    this.logoPath = '';
    this.imageUserPath = '';
    this.topbarUserMenuActive = false;
  }

  ngOnInit(): void {}

  onTopbarUserMenuButtonClick(event: any) {
    this.topbarUserMenuButtonClick.emit(event);
  }

  logoutUser() {
    this.logoutEvent.emit();
  }
}

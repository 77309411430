type WordsOptions = {
  [key: string]: string;
};

export const words: WordsOptions = {
  ABORTED: 'Abortado',
  ADJUSTED: 'Ajustada',
  CALCULATED: 'Calculado',
  CANCELED: 'Cancelada',
  CC: 'Cédula Ciudadanía',
  CELL_PROVIDER: 'Celda Productiva',
  CHECK: 'Chequeo',
  CLIENT: 'Cliente',
  CLOSED: 'Cerrado',
  COMPLETED: 'Completado',
  CORRECTIVE: 'Correctivo',
  CREATED: 'Creada',
  DISPATCH: 'Despachado',
  DISPATCHED: 'Despachada',
  DISTRIBUTED: 'Distribuida',
  DRAFT: 'Borrador',
  ECOMMERCE: 'ECOMMERCE',
  ENLISTED: 'Alistado',
  EXPIRED: 'Expirado',
  FAILED: 'Error',
  false: '',
  FALSE: '',
  FINISHED: 'Finalizada',
  HIGH: 'Alta',
  ISSUED: 'Emitido',
  INCOMPLETE: 'Incompleto',
  INVALID: 'Inválido',
  IN_QUALITY: 'En Calidad',
  IN_PROCESS: 'En Proceso',
  IN_PROGRESS: 'En Proceso',
  IN_TRANSFER: 'En Transferencia',
  BUDGET_PENDING: 'cotización',
  NIT: 'NIT',
  OTHER: 'Otro',
  OVERLOADED: 'Sobrecargada',
  PASSPORT: 'Pasaporte',
  PRESENT: 'Presente',
  NOT_PRESENT: 'No Presente',
  PREVENTIVE: 'Preventivo',
  PRINTED: 'Impreso',
  priority: 'Prioridad',
  PROCESSING: 'Procesando',
  PROVIDER: 'Proveedor',
  READING: 'Leyendo',
  RETURN: 'Devolución',
  SALE_OUT: 'Ventas',
  SELLER: 'Vendedor',
  SIMPLE_ENTRY: 'Entrada Simple',
  SIMPLE_OUTPUT: 'Salida Simple',
  SOLVED: 'Resuelto',
  TAKEOUT: 'Descarga Inventario',
  TI: 'T.I',
  TRANSFER: 'Transferencia',
  true: '',
  TRUE: '',
  VERIFIED: 'Verificada',
  WAREHOUSE: 'WAREHOUSE',
  UNSOLVED: 'No Resuleto',
};

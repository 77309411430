<p-table
  #dt
  [columns]="colSource"
  [lazy]="configs ? (configs.lazy ? configs.lazy : false) : false"
  [value]="dataSource"
  [dataKey]="configs ? (configs.key ? configs.key : '') : ''"
  [paginator]="
    configs ? (configs.paginator ? configs.paginator : false) : false
  "
  [rows]="configs ? (configs.rows ? configs.rows : 0) : 0"
  [sortMode]="configs ? (configs.sortMode ? configs.sortMode : '') : ''"
  [responsive]="true"
  [totalRecords]="dataSource ? (dataSource.length ? dataSource.length : 0) : 0"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="loadLazy($event)"
  [loading]="pending"
  [styleClass]="
    'p-datatable-customers' +
    (configs ? (configs.expandRow ? 'rowexpand-table' : '') : '')
  "
  [resizableColumns]="
    configs ? (configs.resize ? configs.resize : false) : false
  "
  columnResizeMode="expand"
  [exportFilename]="configs ? (configs.fileName ? configs.fileName : '') : ''"
  [(selection)]="itemsSelected"
  (onRowExpand)="onRowExpand($event)"
  (onFilter)="onFilter($event)"
> 
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between">
      <span class="mb-2"
        >{{ 'Total Registros' | translate }}:
        {{ dataSource.length | number }}</span
      >
      <span class="mb-2"
        >{{ 'Total Filtrados' | translate }}:
        {{ filteredTotals | number }}</span
      >
      <span *ngIf="configs?.search" class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          class="form-control"
          pInputText
          placeholder="{{ 'Buscar' | translate }}..."
          (input)="dt.filterGlobal($any($event.target).value, 'contains')"
        />
      </span>
      <div
        class="flex align-content-between flex-wrap mb-2"
        *ngIf="configs?.downloadFile"
      >
        <button
          class="mr-2"
          type="button"
          icon="pi pi-file-o"
          alt="CSV"
          pButton
          pRipple
          (click)="dt.exportCSV()"
          pTooltip="CSV"
          tooltipPosition="bottom"
        ></button>
        <button
          class="p-button-success mr-2"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          alt="Excel"
          (click)="exportExcel()"
          pTooltip="XLS"
          tooltipPosition="bottom"
        ></button>
        <button
          class="p-button-warning mr-2"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-pdf"
          alt="PDF"
          (click)="exportPdf()"
          pTooltip="PDF"
          tooltipPosition="bottom"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em" *ngIf="configs?.multiSelect">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 3em" *ngIf="configs?.expandRow"></th>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        pResizableColumn
      >
        {{ col.header | translate }}
        <p-sortIcon
          [hidden]="!col.sortable"
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th *ngIf="configs && configs.options && configs.options.colHide">
        <span *ngIf="configs?.downloadFile">
          <a class="text-primary" (click)="showFilters()">
            <i class="pi pi-filter"></i> {{ 'Filtros' | translate }}
          </a>
        </span>
      </th>
    </tr>
    <tr
      *ngIf="configs && configs.filters && configs.filters.fields"
      [hidden]="!showFilter"
    >
      <th style="width: 3em" *ngIf="configs?.multiSelect"></th>
      <th
        *ngFor="let col of columns"
        [ngSwitch]="col.field"
        style="overflow: visible"
      >
        <p-multiSelect
          *ngSwitchCase="col.filterField"
          [options]="col.filterOptions"
          [defaultLabel]="col.labelFilter"
          [style]="{ width: '100%' }"
          (onChange)="dt.filter($event.value, col.field, 'in')"
          [panelStyle]="{ minWidth: '12em' }"
        >
          <ng-template let-value pTemplate="selectedItems">
            <div
              *ngFor="let val of value"
              class="ui-multiselected-item-token ui-corner-all"
            >
              <span>{{ val | kanziAppPipes | translate }}</span>
            </div>
            <span
              *ngIf="!value || value.length === 0"
              class="ui-multiselected-empty-token ui-corner-all"
              >{{ col.labelFilter }}</span
            >
          </ng-template>
          <ng-template let-types pTemplate="item">
            <div
              style="
                font-size: 14px;
                margin-top: 4px;
                text-align: left;
                display: inline-block;
              "
            >
              {{ types.value | kanziAppPipes | translate }}
            </div>
          </ng-template>
        </p-multiSelect>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-expanded="expanded"
  >
    <tr>
      <td *ngIf="configs?.multiSelect" style="text-overflow: initial">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="configs?.expandRow">
        <a href="#" [pRowToggler]="rowData">
          <i
            [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
          ></i>
        </a>
      </td>
      <td
        *ngFor="let col of columns"
        [ngSwitch]="col.pipe"
        class="ui-resizable-column"
        [style]="{ overflow: 'hidden', 'text-overflow': 'ellipsis' }"
      >
      <span class="p-column-title">{{ col.header | translate }}</span>
        <div
          *ngIf="col.subfield; then nested_object_content; else normal_content"
        ></div>
        <ng-template #nested_object_content>
          <div *ngIf="rowData[col.field]">
            {{ rowData[col.field][col.subfield] }}
          </div>
        </ng-template>
        <ng-template #normal_content>
          <div *ngSwitchCase="true">
            <span [appStatusStyle]="rowData[col.field]">{{
              rowData[col.field] | kanziAppPipes | translate
            }}</span>
          </div>
          <div *ngSwitchDefault>
            <span [appStatusStyle]="rowData[col.field]">{{
              rowData[col.field]
            }}</span>
          </div>
        </ng-template>
      </td>
      <td *ngIf="configs && configs.options && configs.options.colHide">
        <span *ngIf="configs && configs.options && configs.options.view">
          <a class="text-green" (click)="onRowSelect(rowData)">
            <i class="pi pi-eye"></i>
          </a>
        </span>
        <span *ngIf="configs && configs.options && configs.options.edit">
          <a class="text-blue" (click)="onRowEdit(rowData)">
            <i class="pi pi-pencil"></i>
          </a>
        </span>
        <span *ngIf="configs && configs.options && configs.options.delete">
          <a class="text-red" (click)="onRowDelete(rowData)">
            <i class="pi pi-trash"></i>
          </a>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        {{ emptyMsg | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template
    *ngIf="configs?.expandRow"
    pTemplate="rowexpansion"
    let-rowData
    let-columns="columns"
  >
    <tr>
      <td [attr.colspan]="columns.length + 3">
        <div class="row">
          <div class="col-lg-12">
            <kanzi-dynamic-component
              [dynamicComponent]="dynamicComponent"
              [data]="rowData"
            ></kanzi-dynamic-component>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  ElementRef,
  ViewChild,
  QueryList,
} from '@angular/core';
import * as moment from 'moment';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import {
  AddComponent,
  FilterTableModel,
  ConfigTableModel,
  DataListModel,
  CustomerModel,
  FiltersModel,
} from '@kanzi-apes/kanzi-models';
import { Calendar } from 'primeng/calendar';
import autoTable from 'jspdf-autotable';
import { MultiSelect } from 'primeng/multiselect';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'kanzi-dynamic-new-table',
  templateUrl: './kanzi-dynamic-new-table.component.html',
  styleUrls: ['./kanzi-dynamic-new-table.component.scss'],
})
export class KanziDynamicNewTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() dataSource: DataListModel | null;
  @Input() colSource: any[];
  @Input() configs: any;
  @Input() pending: any;
  @Input() customer: CustomerModel | null;
  @Input() filters: FilterTableModel | FiltersModel | null;
  @Input() dateFilterOption: string;
  @Input() dynamicComponent: AddComponent | null;
  @Output() paginationEvent = new EventEmitter<FilterTableModel | FiltersModel>();
  @Output() selectEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<FilterTableModel | FiltersModel>();
  @Output() clearEvent = new EventEmitter<boolean>();
  @Output() multiselectItemsEvent = new EventEmitter<any[]>();
  @Output() expandRowEvent = new EventEmitter<any>();
  @Output() loadDatasetEvent = new EventEmitter<FilterTableModel | FiltersModel>();
  @Output() rowSelect = new EventEmitter<any>();
  @ViewChild('dt') dt!: any;

  rangeDateCalendar: Date[] = [];
  dataListTable: any[] = [];
  emptyMsg = 'Registros no encontrados.';
  showFilter = false;
  selectedItems: any[] = [];
  filteredTotals = 0;
  dateFilterElement!: ElementRef;
  exportColumns: any[] = [];
  exportExcelColumns: any[] = [];
  columSelect: any;
  objAux: any = {};
  formFilters!: UntypedFormGroup;
  filtersApply: any;

  items: MenuItem[] = [];

  get responsive(): boolean {
    const isResponsive: boolean = window.innerWidth <= 720;
    return isResponsive;
  }

  constructor(private fb: UntypedFormBuilder) {
    this.dataSource = null;
    this.colSource = [];
    this.configs = null;
    this.pending = false;
    this.customer = null;
    this.filters = null;
    this.dateFilterOption = '';
    this.dynamicComponent = null;
  }

  ngOnInit() {
    const fields = this.colSource.map((item) => item.field);
    fields.forEach((columns) => {
      this.objAux[columns] = [, [Validators.required]];
    });
    this.formFilters = this.fb.group(this.objAux);

    this.formFilters.valueChanges.subscribe((value) => {
      if (this.columSelect) {
        this.dt.filter(
          value[this.columSelect.field],
          this.columSelect.field,
          'in'
        );
      }
    });

    this.items = [
      {
        label: 'Descargar CSV',
        icon: 'pi pi-file-o',
        command: () => {
          this.dt.exportCSV();
        },
      },
      {
        label: 'Descargar XLS',
        icon: 'pi pi-file-excel',
        command: () => {
          this.exportExcel();
        },
      },
      {
        label: 'Descargar PDF',
        icon: 'pi pi-file-pdf',
        command: () => {
          this.exportPdf();
        },
      },
    ];
  }

  ngOnChanges() {
    if (this.dataSource) {
      this.dataListTable = [];
      this.dataListTable = this.dataListTable.concat(this.dataSource.results);
    } else {
      this.dataListTable = [];
    }
    this.exportColumns = this.colSource.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportExcelColumns = [];
    this.exportExcelColumns = this.colSource.map((col) => {
      return [col.header, col.field];
    });
  }

  ngOnDestroy() {}

  onRowExpand(event: any) {
    this.expandRowEvent.emit(event.data);
  }

  get itemsSelected(): any[] {
    return this.selectedItems;
  }

  set itemsSelected(items: any[]) {
    this.selectedItems = items;
    this.multiselectItemsEvent.emit(items);
  }

  /**
   *
   * @param event {LazyLoadEvent}
   */
  loadLazy(event: LazyLoadEvent) {
    if (event && event.first && event.rows) {
      const pag = event.first / event.rows + 1;
      this.filters = {
        ...this.filters,
        page: pag,
        page_size: this.configs?.rows,
        customer_id: this.customer ? this.customer.id : 0,
        search: event.globalFilter === null ? '' : event.globalFilter,
      };
      this.paginationEvent.emit(this.filters);
    }
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelect(el: any) {
    this.selectEvent.emit(el);
  }

   /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelected(el:any){
    this.rowSelect.emit(el.data);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowEdit(el: any) {
    this.editEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowDelete(el: any) {
    this.deleteEvent.emit(el);
  }

  onFilter(filters: any) {
    if (filters) {
      this.filtersApply = filters;
      this.filteredTotals = filters.filteredValue.length;
    }
  }

  onFilterDateTable(dates: Date[]) {
    if (dates[1]) {
      const initDate = moment(dates[0]).format('MM/DD/YYYY');
      const endDate = moment(dates[1]).format('MM/DD/YYYY');

      this.filters =
        this.dateFilterOption && this.dateFilterOption === 'modified'
          ? {
              ...this.filters,
              page: 1,
              page_size: 1000,
              modified_min: initDate,
              modified_max: endDate,
            }
          : {
              ...this.filters,
              page: 1,
              page_size: 1000,
              created_min: initDate,
              created_max: endDate,
            };

      this.filterEvent.emit(this.filters);
    }
  }

  clearFilters(check: boolean, dt: any) {
    if (check) {
      this.showFilter = false;
      this.filteredTotals = 0;
      this.rangeDateCalendar = [];
      this.clearEvent.emit(check);
      this.formFilters?.reset();
      dt.reset();
    }
  }

  clearAllFilters(dt: any) {
    this.filteredTotals = 0;
    this.formFilters?.reset();
    this.rangeDateCalendar = [];
    dt.reset();
  }

  showFilters() {
    this.showFilter = this.showFilter ? false : true;
  }

  loadAllDataset() {
    this.filters = {
      ...this.filters,
      page: 1,
      page_size: 1000,
      created_min: '',
      created_max: '',
    };

    this.loadDatasetEvent.emit(this.filters);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      let rows: any = [];
      if (this.filtersApply) {
        rows = this.filtersApply.filteredValue.map((item: any) => {
          let objAux = {};
          this.exportExcelColumns.forEach((column, i) => {
            if (item[column[1]]) {
              Object.assign(objAux, { [column[0]]: item[column[1]] });
            }
          });
          return objAux;
        });
      } else {
        rows = this.dataSource?.results.map((item) => {
          let objAux = {};
          this.exportExcelColumns.forEach((column, i) => {
            if (item[column[1]]) {
              Object.assign(objAux, { [column[0]]: item[column[1]] });
            }
          });
          return objAux;
        });
      }

      const worksheet = xlsx.utils.json_to_sheet(rows, {
        cellDates: true,
      });
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['data'],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        this.configs
            ? this.configs.fileName
              ? this.configs.fileName
              : 'kanzi-report'
            : 'kanzi-report'
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      const doc = new jsPDF.default('landscape');
      let dataExport: any[] | undefined = [];
      if (this.filtersApply) {
        dataExport = this.filtersApply.filteredValue;
      } else {
        dataExport = this.dataSource?.results;
      }
      autoTable(doc, {
        columns: this.exportColumns,
        body: dataExport,
        tableWidth: 'auto',
        pageBreak: 'auto',
        margin: 10,
      });
      doc.save(this.configs ? this.configs.fileName : 'kanzi-report');
    });
  }

  selectFilter($event: any, col: any, overlaypanel: any) {
    this.columSelect = col;
    if ($event) {
      overlaypanel.toggle($event);
    }
  }

  clearFilterColumn(field: any, overlaypanel: OverlayPanel) {
    this.formFilters?.get(field)?.reset();
    if (this.formFilters?.pristine) {
      this.filteredTotals = 0;
      this.formFilters.reset();
      this.dt.reset();
    }
    overlaypanel.hide();
  }
}

<p-table
  #dt
  [value]="RefillList"
  [columns]="colSource"
  [rows]="10"
  [loading]="pending"
  [paginator]="
    configs ? (configs.paginator ? configs.paginator : false) : false
  "
  [sortMode]="configs ? (configs.sortMode ? configs.sortMode : '') : ''"
  [globalFilterFields]="['sku_id', 'sku_display_name', 'measure_of_unit']"
  [totalRecords]="RefillList.length"
  [exportFilename]="configs ? (configs.fileName ? configs.fileName : '') : ''"
  [responsive]="true"
  [styleClass]="'kanzi-table'"
  [resizableColumns]="true"
  editMode="row"
  dataKey="id"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between">
      <span class="mb-2"
        >{{ 'Total Registros' | translate }}:
        {{ RefillList.length | number }}</span
      >
      <span *ngIf="configs?.search" class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          class="form-control"
          pInputText
          placeholder="{{ 'Buscar' | translate }}..."
          (input)="dt.filterGlobal($any($event.target)?.value, 'contains')"
        />
      </span>
      <div
        class="flex align-content-between flex-wrap mb-2"
        *ngIf="configs?.downloadFile"
      >
        <button
          class="mr-2"
          type="button"
          icon="pi pi-file-o"
          iconPos="left"
          alt="CSV"
          pTooltip="CSV"
          tooltipPosition="bottom"
          pButton
          pRipple
          (click)="dt.exportCSV()"
        ></button>
        <button
          class="p-button-success mr-2"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          iconPos="left"
          label=""
          alt="Excel"
          pTooltip="XLS"
          tooltipPosition="bottom"
          (click)="exportExcel()"
        ></button>
        <button
          class="p-button-warning mr-2"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-pdf"
          iconPos="left"
          label=""
          alt="PDF"
          pTooltip="PDF"
          tooltipPosition="bottom"
          (click)="exportPdf()"
        ></button>
      </div>
      <span class="mt-4" *ngIf="configs && configs.filters?.fields">
        <button
          type="button"
          pButton
          alt="Filtrar"
          pTooltip="Filtrar"
          tooltipPosition="bottom"
          icon="pi pi-filter"
          class="mr-2 p-button-info p-button-outlined p-button-sm"
          (click)="showFilters()"
        ></button>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pSortableColumn="sku_external_id">
        {{ 'Código' | translate }}
        <p-sortIcon
          field="sku_external_id"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th pSortableColumn="sku_id">
        {{ 'ID' | translate }}
        <p-sortIcon
          field="sku_id"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th pSortableColumn="sku_display_name">
        {{ 'Nombre' | translate }}
        <p-sortIcon
          field="sku_display_name"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th pSortableColumn="amount">
        {{ 'Cantidad' | translate }}
        <p-sortIcon
          field="amount"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th pSortableColumn="measure_of_unit">
        {{ 'Unidad de medida' | translate }}
        <p-sortIcon
          field="measure_of_unit"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th *ngIf="configs && configs.validation_user" style="width: 8em"></th>
    </tr>
    <tr *ngIf="configs && configs.filters?.fields" [hidden]="!showFilter">
      <th
        *ngFor="let col of columns"
        [ngSwitch]="col.field"
        style="overflow: visible"
      >
        <!-- Filtros -->
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-filter"
          class="p-button-rounded p-button-text"
          [ngClass]="{
            'p-button-help': formFilters && !formFilters.get(col.field)?.errors
          }"
          (click)="selectFilter($event, col, op)"
        ></button>
        <button
          *ngIf="formFilters && !formFilters.get(col.field)?.errors"
          pButton
          pRipple
          type="button"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="clearFilterColumn(col.field, op)"
        ></button>

        <p-overlayPanel [style]="{ width: '300px' }" appendTo="body" #op>
          <ng-template pTemplate>
            <h4>
              <strong>{{ col.header }}</strong>
            </h4>
            <form *ngIf="formFilters" [formGroup]="formFilters">
              <p-multiSelect
                *ngSwitchCase="col.filterField"
                [options]="col.filterOptions | kanziNameOrder"
                [defaultLabel]="col.labelFilter"
                [style]="{
                  width: '100%',
                  'margin-bottom': '30px',
                  'margin-top': '10px'
                }"
                [formControlName]="col.field"
                [panelStyle]="{ minWidth: '12em' }"
              >
                <ng-template let-value pTemplate="selectedItems">
                  <div *ngFor="let val of value">
                    <span>{{ val | kanziAppPipes }}</span>
                  </div>
                  <span *ngIf="!value || value.length === 0">{{
                    col.labelFilter
                  }}</span>
                </ng-template>
                <ng-template let-types pTemplate="item">
                  <div
                    style="
                      font-size: 14px;
                      margin-top: 4px;
                      text-align: left;
                      display: inline-block;
                    "
                  >
                    {{ types.value | kanziAppPipes }}
                  </div>
                </ng-template>
              </p-multiSelect>

              <div class="grid justify-content-between ml-2 mr-2">
                <button
                  (click)="op.hide()"
                  pButton
                  type="button"
                  label="Aplicar"
                  class="p-button-text"
                ></button>
                <button
                  pButton
                  type="button"
                  label="Limpiar"
                  (click)="clearFilterColumn(col.field, op)"
                  class="p-button-text p-button-secondary"
                ></button>
              </div>
            </form>
          </ng-template>
        </p-overlayPanel>
        <!-- End FILTROS -->
      </th>
      <th *ngIf="configs && configs.options && configs.options.edit"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="rowData">
      <td>{{ rowData.sku_external_id }}</td>
      <td>
        {{ rowData.sku_id }}
      </td>
      <td>
        {{ rowData.sku_display_name }}
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              type="number"
              min="0"
              step="0.1"
              class="form-control"
              [(ngModel)]="newDataAmount"
              placeholder="0.0"
            />
          </ng-template>
          <!-- <ng-template pTemplate="output">
            {{
              envService.environment?.isQuantity
                ? rowData.quantity
                : rowData.amount
            }}
          </ng-template> -->
          <ng-template pTemplate="output">
            {{
              rowData.amount
            }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ rowData.measure_of_unit }}</td>
      <td
        *ngIf="configs && configs.options && configs.options.edit"
        style="text-align: center"
      >
        <button
          *ngIf="!editing"
          pButton
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          class="p-button-warning mr-2"
          (click)="onRowEditInit(rowData)"
        ></button>
        <button
          *ngIf="!editing"
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-danger mr-2"
          (click)="onRowDelete(ri, rowData)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          class="p-button-success mr-2"
          (click)="onRowEditSave(ri, rowData)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          class="p-button-danger"
          (click)="onRowEditCancel(rowData, ri)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div
      class="grid justify-content-start align-items-center pt-2 pl-2"
      style="width: 100%"
    >
      <button
        type="button"
        icon="fa-solid fa-wrench"
        (click)="showDialogToAdd()"
        pButton
        [disabled]="!configs?.validation_user"
        label="{{ 'Añadir repuestos' | translate }}"
        class="p-button-help mr-3"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr *ngIf="!configs?.validation_user">
      <td [attr.colspan]="columns.length">
        {{ 'Registros no encontrados.' | translate }}
      </td>
    </tr>
    <tr *ngIf="configs?.validation_user">
      <td [attr.colspan]="columns.length + 1">
        {{ 'Registros no encontrados.' | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>
<p-toast></p-toast>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import {
  AddComponent,
  ConfigTableModel,
  FilterTableModel,
  CustomerModel,
} from '@kanzi-apes/kanzi-models';
import autoTable from 'jspdf-autotable';
import * as FileSaver from 'file-saver';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.0.0
 *
 * Component to create a configurable table.
 */
@Component({
  selector: 'kanzi-dynamic-simple-table',
  templateUrl: './kanzi-dynamic-simple-table.component.html',
  styleUrls: ['./kanzi-dynamic-simple-table.component.scss'],
})
export class KanziDynamicSimpleTableComponent implements OnInit {
  @Input() customer: CustomerModel | null;
  @Input() dataSource: any[];
  @Input() colSource: any[];
  @Input() configs: ConfigTableModel | null;
  @Input() pending: boolean;
  @Input() dynamicComponent: AddComponent | null;
  @Output() paginationEvent = new EventEmitter<FilterTableModel>();
  @Output() selectEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<FilterTableModel>();
  @Output() clearEvent = new EventEmitter<boolean>();
  @Output() expandRowEvent = new EventEmitter<any>();
  @Output() multiselectItemsEvent = new EventEmitter<any[]>();

  emptyMsg = 'Registros no encontrados.';
  filters: FilterTableModel | null = null;
  showFilter = false;
  filteredTotals = 0;
  selectedItems: any[] = [];
  exportColumns: any[] = [];
  exportExcelColumns: string[] = [];

  constructor() {
    this.customer = null;
    this.dataSource = [];
    this.colSource = [];
    this.configs = null;
    this.pending = false;
    this.dynamicComponent = null;
    this.exportColumns = this.colSource.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportExcelColumns = [];
    this.exportExcelColumns = this.colSource.map((col) => col.field);
  }

  ngOnInit() {}

  get itemsSelected(): any[] {
    return this.selectedItems;
  }

  set itemsSelected(items: any[]) {
    this.selectedItems = items;
    this.multiselectItemsEvent.emit(items);
  }

  /**
   *
   * @param event {LazyLoadEvent}
   */
  loadLazy(event: LazyLoadEvent) {
    if (event && event.first && event.rows) {
      const pag = event.first / event.rows + 1;
      const filter: FilterTableModel = {
        page: pag,
        page_size: this.configs?.rows,
        search: event.globalFilter === null ? '' : event.globalFilter,
        sort_field: event.sortField,
        sort_order: event.sortOrder,
      };
      this.paginationEvent.emit(filter);
    }
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelect(el: any) {
    this.selectEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowEdit(el: any) {
    this.editEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowDelete(el: any) {
    this.deleteEvent.emit(el);
  }

  onFilterDateTable(dates: string[]) {
    if (dates[1]) {
      const initDate = moment(dates[0]).format('MM/DD/YYYY');
      const endDate = moment(dates[1]).format('MM/DD/YYYY');

      this.filters = {
        ...this.filters,
        page: 1,
        page_size: 200,
        created_min: initDate,
        created_max: endDate,
      };

      this.filterEvent.emit(this.filters);
    }
  }

  clearFilters(check: boolean) {
    if (check) {
      this.showFilter = false;
      this.clearEvent.emit(check);
    }
  }

  showFilters() {
    this.showFilter = this.showFilter ? false : true;
  }

  onFilter(filters: any) {
    if (filters) {
      this.filteredTotals = filters.filteredValue.length;
    }
  }

  onRowExpand(event: any) {
    this.expandRowEvent.emit(event.data);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const rows = this.dataSource?.map((item) => {
        let objAux = {};
        this.exportExcelColumns.forEach((column, i) => {
          if (item[column]) {
            Object.assign(objAux, { [column]: item[column] });
          }
        });
        return objAux;
      });
      if (rows) {
        const worksheet = xlsx.utils.json_to_sheet(rows, {
          cellDates: true,
          header: this.exportExcelColumns,
        });
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(
          excelBuffer,
          this.configs
            ? this.configs.fileName
              ? this.configs.fileName
              : 'kanzi-report'
            : 'kanzi-report'
        );
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      const doc = new jsPDF.default('landscape');
      autoTable(doc, {
        columns: this.exportColumns,
        body: this.dataSource,
        tableWidth: 'auto',
        pageBreak: 'auto',
        margin: 10,
      });
      doc.save(this.configs ? this.configs.fileName : 'kanzi-report');
    });
  }
}

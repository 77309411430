/**
 * Environment Configuration Model
 * @Author "Andres Escobar Ciceri"
 */

import { MenuMode } from '@kanzi-apes/kanzi-diamond-ui';

export interface KanziEnvironmentModel {
  production: boolean;
  kongApi: string;
  kongTrackingFile: string;
  apesPdfCreatorApi?: string;
  masterFileUrl?: string;
  templatePdf?: string;
  flightReportApiUrl?: string;
  nodeRedApi?: string;
  socketIOServer?: string;
  noderedCustomer?: string;
  qsApiUrl?: string;
  qsApiUrlCommon?: string;
  qsCustomer?: string;
  qsApiUrlAvianca?: string;
  isQuantity?: boolean;
  selectedLocation?:boolean;
  filterByLocation?:boolean;
  formats: {
    date: string;
  };
  assets: {
    logo: string;
    background: string;
    root: string;
    rootClient: string;
    paths: {
      css: string;
      logos: string;
      backgrounds: string;
      js: string;
    };
  };
  configUI?: {
    mainMenuStyle: MenuMode;
    scale: number;
  };
  apiModules: {
    auth: string;
    config: string;
    inventory?: string;
    operations?: string;
    rfid?: string;
    audits?: string;
    logistics?: string;
    customers?: string;
    integrations?: string;
    maintenance?: string;
    stock?: string;
    invoicing?: string;
  };
  modules: {
    enabledTypeItems?:boolean;
    loadMasterFileLocation?: boolean;
    customerType: string;
    dashboards?: boolean;
    isEnableButtonExternalId?: boolean;
    isFilterWerehouse?: boolean;
    analyticsReports?: {
      parent: boolean;
      permissionName?: string;
      sales?: boolean;
      salesDBName?: string;
      inventory?: boolean;
      inventoryDBName?: string;
      inventorykardex?: boolean;
      inventoryKardexDBName?: string;
      moves?: boolean;
      movesDBName?: string;
      returns?: boolean;
      returnsDBName?: string;
      invoicing?: boolean;
      invoicingDBName?: string;
      custom?: boolean;
      customDBMenu?: string;
      customDBName?: string;
      maintenance?: boolean;
      audits?: boolean;
      audtisDBName?: string;
      flightReport?: boolean;
      qsApiName?: string;
      customDBNameAcianca?: string;
      operations?: boolean;
      operationsDBName?: string;
    };
    contacts?: boolean;
    editContacts?: boolean;
    discountsContacts?: boolean;
    disabledCreateContacts?: boolean;
    contactsParams?: boolean;
    automationId?: boolean;
    products?: {
      parent: boolean;
      products: boolean;
      productCode?: boolean;
      assets: boolean;
      maintenanceTrace: boolean;
      unsubscribeProduct?: boolean;
      deleteProduct?: boolean;
      aditionalParamsInactiveTags?: boolean;
      inactiveTagsAccions?: boolean;
      filterTypeSku?: boolean;
      setProperties?: boolean;
      setImagesProduct?: boolean;
      loadMasterFile?: boolean;
      isDisabledCreateProduct?: boolean;
      editProducts?: boolean;
    };
    inventories?: {
      parent: boolean;
      products: boolean;
      assets: boolean;
      booking: boolean;
      bookingCreate: boolean;
      bookingList: boolean;
      hideColumnsSummary?: boolean;
    };
    sales?: {
      parent?: boolean;
      layoutCreator?: boolean;
      disabledSalesReports?:boolean;
    };
    moves?: {
      parent: boolean;
      dashboards?: boolean;
      sales?: boolean;
      price?: boolean;
      moves?: boolean;
      moveExternalId?: boolean;
    };
    production?: {
      parent: boolean;
      productionOrder: boolean;
      productionDelete?: boolean;
      productionLocationType: string;
      purchaseOrder: boolean;
      purchaseLocationType: string;
      printOrder: boolean;
      filterOrder: boolean;
      external_id: boolean;
      purchaseOrderDelete?: boolean;
      purchaseOrderEdit?: boolean;
      purchaseOrderEditAddLines?: boolean;
      productionPositioning?: boolean;
      purchasePositioning?: boolean;
      productionOrderProperties?: boolean;
      purchaseOrderProperties?: boolean;
      productionOrderIdHide?: boolean;
      hideTagTemplate?: boolean;
      skuIdLines?: boolean;
      useSkuId?: boolean;
    };
    operations?: {
      parent: boolean;
      storeOrder: boolean;
      distributionOrder: boolean;
      workOrder: boolean;
      packings: boolean;
      dispatchPacks: boolean;
      budgets?: boolean;
      booking?: boolean;
      validationClient?: boolean;
    };
    audits?: {
      parent: boolean;
      calendar?: boolean;
      products: boolean;
      assets: boolean;
      classic?: boolean;
      virtualStore?: boolean;
    };
    logistics?: {
      parent: boolean;
      shippingOrders?: boolean;
      shippingReturns?: boolean;
      trackOrderCreate: boolean;
      trackings: boolean;
      printers: boolean;
      pickingFile?: boolean;
      hideStatusTracking?: boolean;
      sendSkuId?: boolean;
      formStatic?: boolean;
      hideAddressType?: boolean;
      shippingVerified?: boolean;
      onlySkuId?:boolean
    };
    logisticPacking?: {
      parent: boolean;
      list: boolean;
      listStates: boolean;
      create: boolean;
      filterUser?: boolean;
    };
    maintenance: {
      hasDiagnostics?:boolean;
      budgets?:ConditionalBudgets;
      module_name?: string;
      parent: boolean;
      scheduler: boolean;
      orders: boolean;
      // workOrders?: { budget?: boolean };
      checklistSheetLlines?: boolean;
      byGroups?: boolean;
      pendingAssets?: boolean;
      byTypes?: boolean;
      pdfGenerator?: {
        revisionLine: boolean;
        revisionLineTemplate: string;
        revisionMarginsTemplate: number[];
      };
      pdfGeneratorAdditional?: {
        revisionLine: boolean;
        revisionLineTemplate: string;
        revisionMarginsTemplate: number[];
        pdfGeneratorName?: string;
        locationName?: string;
      };

      otUndiagnosed?:{
        hasDescription:boolean
      }
    };
    alerts?: boolean;
    shipments?: {
      parent: boolean;
      list?: boolean;
      packages?: boolean;
    };
  };
  configModules?: {
    locations?: boolean;
    iconLocation?:boolean;
    customers?: boolean;
    logistics?: boolean;
    users?: boolean;
    maintenances?: boolean;
  };
  viewRigthMenu?: boolean;
}

export interface EnvironmentModel {
  production: boolean;
  kongApi: string;
  sentryApi: string;
  kongTrackingFile: string;
  formats: {
    date: string;
  };
  apiModules: {
    assets: string;
    auth: string;
    config: string;
    inventory: string;
    operations: string;
    rfid: string;
    audits: string;
    logistics: string;
    customers: string;
    integrations?: string;
    maintenance?: string;
    stock?: string;
  };
  assets: {
    logo: string;
    background: string;
    root: string;
    rootClient: string;
    paths: {
      css: string;
      logos: string;
      backgrounds: string;
      js: string;
    };
  };
  modules: {
    customerType?: string;
    dashboards: {
      parent: boolean;
      moves: boolean;
      purchase: boolean;
      inventory: boolean;
      users: boolean;
      inventoryBar: boolean;
      entries: boolean;
      sells?: boolean;
    };
    contacts: boolean;
    products: {
      parent: boolean;
      products: boolean;
      assets: boolean;
      maintenanceTrace: boolean;
    };
    inventories: {
      parent: boolean;
      products: boolean;
      assets: boolean;
      booking: boolean;
      bookingCreate: boolean;
      bookingList: boolean;
    };
    moves: boolean;
    production: {
      parent: boolean;
      productionOrder: boolean;
      purchaseOrder: boolean;
      printOrder: boolean;
      filterOrder: boolean;
      external_id: boolean;
    };
    operations: {
      parent: boolean;
      storeOrder: boolean;
      distributionOrder: boolean;
      workOrder: boolean;
      packings: boolean;
      dispatchPacks: boolean;
      external_id_config: {
        filed?: string;
        subfield?: string;
      };
    };
    audits: {
      parent: boolean;
      calendar?: boolean;
      products: boolean;
      assets: boolean;
    };
    hardware: boolean;
    logistics: {
      parent: boolean;
      shippingOrders?: boolean;
      trackOrderCreate: boolean;
      trackings: boolean;
      printers: boolean;
    };
    logisticPacking: {
      parent: boolean;
      list: boolean;
      listStates: boolean;
      create: boolean;
    };
    maintenance: {
      parent: boolean;
      scheduler: boolean;
      orders: boolean;
    };
    shared: {
      parent: boolean;
    };
    alerts: boolean;
  };
  configModules: {
    locations: boolean;
    customers: boolean;
    logistics: boolean;
    users: boolean;
  };
  views: {
    packs_list_view: {
      visible: boolean;
      columns_view: {
        created_column: boolean;
        verified_column: boolean;
        dispatched_column: boolean;
      };
      table_view: any | null;
      dispatch_form: {
        visible: boolean;
        form: {
          location: {
            type: string;
          };
          packs_state_filter: string;
        };
        table: {
          lazy: boolean;
          showHeaderCheckbox: boolean;
        };
      };
    };
    inventory_summary_list_view: {
      visible: boolean;
      type_view: string;
      tabs_info: {
        count: number;
        tabs: {
          name: string;
          visible: boolean;
        }[];
      };
    };
    production_order_view: {
      cell_provider_select: boolean;
      print_template_select: boolean;
    };
    viewSellerOption: boolean;
  };
  viewRigthMenu: boolean;
}

export type ConditionalBudgets = 'OPTIONAL' | 'REQUIRED' | 'NONE'

import { Injectable, Optional } from '@angular/core';
import { Environment, KanziEnvironmentModel } from '@kanzi-apes/kanzi-models';

export class EnvironmentData extends Environment {}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private _environment: KanziEnvironmentModel | null = null;
  constructor(@Optional() env?: Environment) {
    if (env) {
      this._environment = env;
    }
  }

  get environment() {
    return this._environment;
  }
}

import { KanziStatus } from '@kanzi-apes/kanzi-models';
export function styleColorState(state: string): string {
  switch (state) {
    case KanziStatus.CREATED: {
      return '#2196F3';
    }

    case KanziStatus.INCOMPLETE: {
      return '#f39c12';
    }

    case KanziStatus.COMPLETED: {
      return '#00a65a';
    }
    case KanziStatus.PROCESSING: {
      return '#f39c12';
    }
    case KanziStatus.CALCULATED: {
      return '#00a65a';
    }
    case KanziStatus.IN_PROGRESS: {
      return '#f39c12';
    }
    case KanziStatus.FINISHED: {
      return '#f39c12';
    }
    case KanziStatus.CANCELED: {
      return '#dd4b39';
    }
    case KanziStatus.ADJUSTED: {
      return '#00a65a';
    }

    default:
      return '';
  }
}
